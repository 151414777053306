.allegations-summary {
  max-width: 500px;
}

.prepare-letter-button {
  background: linear-gradient(5.12deg, #50E3C2 0%, #4AC1FF 100%);
  width: 400px;
  border-radius: 0;
  font-weight: normal;
  padding: 10px 5px;
}

.page-title {
  color: #6236FF;
}

