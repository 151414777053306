.list-style {
  list-style-type: circle;
  margin-left: 30px;
}

.background {
  background: #BD7AE3;
}

p > span.background {
    width: 40px !important;
    background: #BD7AE3;
    padding: 0 10px;
}
