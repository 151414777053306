.search-results {
    position: absolute;
    max-height: 40vh;
    background: #fff;
    border: 1px #ddd solid;
    width: 240px;
    overflow-y: auto;
    z-index: 999;
    border-radius: 3px;
    padding: 10px;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

.list_item {
    &:hover {
        background-color: lightgrey;
      }
                    list-style: none;
}

.result-item {
    margin-left: 10px;
}
