.container {
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
  color: #000;
}

.sigContainer {
  width: 40%;
  height: 40%;
  margin: 0 auto;
  padding: 0 15px;
}

.sigPad {
  width: 100%;
  height: 100%;
  background-color: lightgray;
}

.buttons {
  width: 100%;
  height: 30px;
}

.email_preview {
  width: 50vw;
  height: 50vh;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: red;
}

.buttons {
  width: 100%;
  height: 30px;
}
