.popover {
    max-width: 460px !important;
    width: 450px !important;
    font-size: 1.1em;
    z-index: 1040 !important;
}

.btn-icon {
    height: 3.6rem !important;
    min-width: 2.675rem !important;
    width: 3.6rem !important;
    padding: 0;
    font-size: 2.675rem !important;
    overflow: hidden;
    position: relative;
    line-height: normal;
}

.tab-background {
    background-color: #EDEFEF;
    border-radius: 0.25rem;
}

.pointer {
    cursor: pointer;
}

.uploaderHeader {
    background: linear-gradient(5.71deg, #7254F2 0%, #55F0FC 100%) !important;
    border-radius: 2px 2px 0px 0px !important;
}
