.hierarchy-container{
    padding: 0px 0px 0px 260px;
    text-align: center;
  }
  .search{
    color:grey;
    margin: 5px;
  }
  .search-contianer{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .legend_small{
    position: absolute;
    bottom:0px;
    background-color:white;
    display: flex;
    flex-direction:column;
    align-items: left;
    z-index: 100;
    border-radius: 10px;
    -moz-box-shadow:     0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    -webkit-box-shadow:  0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    box-shadow:          0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    padding: 10px;
  }

  .user_legend_icon{
    color: #9368d0 
  }
  .access_legend_icon{
    color: #131042 
  }

  .legend_row{
   display: flex; 
   flex-direction: row;
   align-items: center;
  }

  .node_text_title{
    margin: 5px;
    font-size: 1.8rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .node_text{
    margin: 5px;
    font-size: 1.4rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .node-image-rect{
    display: none;
  }

  .node-rect{
   fill: #9368d0  !important;
  }

  .can-access{
    fill: #131042  !important;
  }



  .toolbar_small{
    position: absolute;
    background-color:white;
    display: flex;
    flex-direction:column;
    align-items: center;
    z-index: 100;
    border-radius: 10px;
    -moz-box-shadow:     0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    -webkit-box-shadow:  0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    box-shadow:          0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    padding: 10px;
}

.toolbar{
      position: absolute;
      background-color:white;
      display: flex;
      flex-direction:column;
      align-items: center;
      width: 350px;
      z-index: 100;
      border-radius: 10px;
      -moz-box-shadow:     0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
      -webkit-box-shadow:  0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
      box-shadow:          0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
      padding: 10px;
  }

.toolbar label{
      color: #9368d0;
  }

.hierarchy-chart{
    background-image: none;
    border: none;
  }

.hierarchy-chart__container{
    border: none;
  }

  .orgchart.hierarchy-chart > ul > li > ul li::before {
    border-top-color: #9368d0;
  }
  
  .orgchart.hierarchy-chart > ul > li > ul li .oc-node::before,
  .orgchart.hierarchy-chart ul li .oc-node:not(:only-child)::after {
    background-color: #9368d0;
  }
  
  .orgchart ul li .oc-node .oc-heading {
    background-color: #9368d0;
  }

  .link{
    stroke: grey !important;
  }
  
  .orgchart ul li .oc-node .oc-content {
    border: 1px solid #9368d0;
  }
