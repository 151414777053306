.document-modal-header {
  background: linear-gradient(175.71deg, #3023AE 0%, #C86DD7 101.5%);
  border-radius: 2px 2px 0px 0px;
  font-size: 18px
}

.document-modal-body {
  min-height: 470px;
}

.document-modal-select {
  margin-left: 25px;
  width: 400px
}

.modal-file-attachment {
  background: #ECEBEB;
  color: #504C53;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  top: 10.78%;
  bottom: 81.25%;
  text-transform: lowercase;
  height: 35px;
  border: 1px solid #CECECE;
  box-sizing: border-box;
  padding-top: 7px;
  padding-left: 25px;
}

.modal-text-input {
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 6px;
  width: 390px;
  margin-left: 25px;
  margin-bottom: 15px;
}

.modal-close-button {
  border: 2px solid #BD7AE3;
  box-sizing: border-box;
  box-shadow: 0px 15px 20px rgba(175, 175, 175, 0.223987);
  border-radius: 2px;
  height: 480px;
  width: 160px;
  font-size: 13px;
}

.modal-send-button {
  background: #8461C9;
  box-shadow: 0px 15px 20px rgba(175, 175, 175, 0.223987);
  border-radius: 2px;
  height: 48px;
  width: 140px;
  font-size: 13px;
  color: white
}
