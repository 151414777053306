.case-status-bar {
  margin-left: 15px;
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 1.09px;
  text-transform: capitalize;
  height: 30px;
  background: linear-gradient(225deg, #FF69A4 0%, #FFB97A 77.98%);
}





