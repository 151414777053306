.document-status-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  align-items: center;
  font-size: 10px;
}

.allegations-summary {
  max-width: 500px;
}

.document-status-circle-approved {
  background: turquoise;
  padding: 0;
}

.document-status-circle-requires-revision {
  background: orange;
  padding: 0;
}

.view-document-button {
  background: turquoise;
  border-radius: 0;
  padding: 0;
  margin-left: 30px !important;
  width: 30px;
  height: 30px;
}

.edit-document-button {
  border-radius: 0;
  padding: 0;
  margin-left: 30px !important;
  width: 30px;
  height: 30px;
}

.upload-signed-document-button, .issue-suspension-letter-button, .approve-final-letter-button {
  height: 40px;
  width: 200px;
  box-sizing: border-box;
  border-radius: 0;
  font-weight: normal;
  color: white;
  font-size: 13px;
  background: #8461C9;
  padding: 0;
}

.upload-signed-document-button, {
  margin-left: 10px;
}

.approve-final-letter-button {
  margin-left: 10px;
}

.email-draft-letter-button {
  height: 40px;
  width: 200px;
  border: 2px solid #8461C9;
  box-sizing: border-box;
  border-radius: 0;
  color: #8461C9;
  font-size: 13px;
  background: #F8F7F7;
  margin-right: 10px;
  padding: 0;
}

.system-email-table-row-item {
  width: 200px;
}
