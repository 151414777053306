.card_header_container {
  border-bottom: 1px solid #8461c9;
  display: flex;
  justify-content: space-between;
}


.widgets {
  width: 100%;
  background-color: white;
  padding: 21px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}


.widget {
  height: auto!important;
  h2 {
    color: #8461c9;
    border-bottom: 1px solid #8461c9;
    margin-bottom: 0;
    padding-bottom: 13px;
    font-size: 14px;
    font-weight: 300;
  }

  .task {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #c6c6c6;
    .status {
      display: flex;
      align-items: center;
      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50px;
        margin-right: 5px;
        display: inline-block;
      }

      .inProgress {
        color: #50e3c2;
        .dot {
          background-color: #50e3c2;
        }
      }
      .dueSoon {
        color: #f7b500;
        .dot {
          background-color: #f7b500;
        }
      }
      .overdue {
        color: #e02020;
        .dot {
          background-color: #e02020;
        }
      }
    }
    .timeStamp {
      color: #9ea1a5;
      position: absolute;
      top: 12px;
      right: 2px;
      font-size: 11px;
      font-weight: 300;
    }
    .description {
      color: #6d7278;
      margin: 6px 0;
    }
    .assignee {
      color: #8461c9;
      margin-top: 6px;
    }
  }
}

.myTasks {
  .card-body {
    overflow-anchor: none;
    touch-action: auto;
    max-height: 310px;
    overflow: hidden !important;
    position: relative;
  }
  -webkit-overflow-scrolling: touch;
}

.teamTasks {
  .card-body {
    overflow-anchor: none;
    touch-action: auto;
    max-height: 310px;
    overflow: hidden !important;
    position: relative;
  }
  .table {
    thead {
      tr {
        th {
          font-size: 14px;
          font-weight: 700;
          color: #6236ff;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-top: 17px!important;
          padding-bottom: 17px!important;
          color: #6236ff;
          background-color: transparent !important;
          font-size: 14px;
          font-weight: 300;
          &.status {
            display: flex;
            align-items: center;
            .dot {
              width: 12px;
              height: 12px;
              border-radius: 50px;
              margin-right: 5px;
              display: inline-block;
            }

            .inProgress {
              color: #50e3c2;
              .dot {
                background-color: #50e3c2;
              }
            }
            .dueSoon {
              color: #f7b500;
              .dot {
                background-color: #f7b500;
              }
            }
            .overdue {
              color: #e02020;
              .dot {
                background-color: #e02020;
              }
            }
          }
        }
      }
    }
  }
}
