.task-container {
  background-color: white;
  margin-left: 65px;
  margin-top: 15px;
  position: relative;
}






