.case-progress-bar-container {
  position: relative;
  display: flex;
}

.case-progress-step {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: center;
    z-index: 0;
}

.case-progress-bar {
    top: 17px;
    border-color: white;
    height: 5px;
    content: "";
    position: absolute;
    left: 50%;
    width: 100%;
    z-index: -1;

  &.case-step-completed {
    background: linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  }

  &.case-step-incomplete {
    background: lightgray;
    opacity: 50%;
  }

  &.case-step-active {
    background: lightgray;
    opacity: 50%;
  }
}

.case-progress-step-circle {
    display: flex;
    margin: 0 auto 10px;
    width: 40px;
    height: 40px;
    background: white;
    border: 4px solid white;
    border-radius: 100%;
    align-items: center;
    font-size: 10px;
    z-index: 2;
    padding-left: 11px;

  &.case-step-completed {
    background: linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
    color: white;
    padding-left: 11px;
  }

  &.case-step-active {
    background: linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
    padding-left: 12px;
    padding-bottom: 2px;
    color: white;
    font-size: small;
  }

  &.case-step-incomplete {
    background: white;
    color: lightgray;
    padding-left: 12px;
    padding-bottom: 2px;
    font-size: small;
  }
}




