@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }
  
.open-card{
    height: 11vh;
    overflow-x: hidden;
    overflow-y: scroll;
    @include scrollbars(15px, lightgrey, linear-gradient(0deg, #6f42c1 0%, #6f42c1 100%));
    text-transform: capitalize;
}