.employee_bar_container {
        overflow-x: hidden;
        width: 520px;
        height: calc(100vh - 60px) !important;
        z-index: 1;
        background-color: whitesmoke;
        position: absolute;
        right: 0;
        border: 1px solid #ccc;
        -moz-box-shadow: -5px 0 5px -5px #333;
        -webkit-box-shadow: -5px 0 5px -5px #333;
        box-shadow: -5px 0 5px -5px #333;
}

.toggle-icon {
        color: grey;
        position: absolute;
        top: 10px;
        left:10px;
}