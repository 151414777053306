.static-indicator-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}

.static-indicator-grid {
    .sub {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex: calc(100% / 4);
        padding: 0 10px;
    }
    .grid-item {
        flex: calc(100% / 2);
        min-height: 120px;
        border-radius: 20px;
        border: 10px solid #F5F5F5;
        display: inline;
        padding: 4px;
        .icon {
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0.10;
            overflow: hidden;
        }
        .icon-icon {
            height: auto;
            max-height: 110px;
            margin-left: calc(100% - 100px);
            fill: white;
        }
        .icon-container {
            position: relative;
        }
        .static-indicator-info {
            width: 50%;
            .number {
                margin-right: 20px;
            }
        }
    }
    .sub-grid-item {
        flex: calc(100% / 4);
        min-height: auto;
        display: inline;
        padding: 5px 20px;
        word-wrap: normal;
        .icon {
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            z-index: 1;
        }
        .icon-icon {
            min-height: 100%;
            margin-top: 20px;
            margin-left: calc(100% - 50px);
            fill: #BD7AE3;
        }
        .icon-container {
            position: relative;
        }
        .row {
            border-radius: 4px;
            box-shadow: 0 0 0 0 rgb(255, 255, 255), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.1);
            background: white;
        }
        .number {
            color: #8461C9;
            padding-right: 20px;
        }
        .percentage {
            color: #8461C9;
        }
        .title {
            color: #6236FF;
        }
    }
    .static-indicator-info {
        padding: 0 10px;
        p {
            color: white;
        }
        span {
            padding: 0;
        }
        .percentage {
            font-size: 20px;
            font-weight: 200;
            height: auto;
            text-align: start;
            margin: 0 6px;
            //padding-left: 15px;
        }
        .number {
            font-size: 35px;
            font-weight: 200;
            height: auto;
            text-align: start;
            margin: 0 4px;
            padding-left: 15px;
        }
        .title {
            align-items: center;
            text-align: start;
            padding: 0 0 0 15px;
            width: 100%;
            font-weight: 500;
            word-wrap: normal;
            font-size: 100%;
        }
    }
}