.hero {
    background-image: url("../../../assets/img/login-background.png");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.form-container {
    margin-top: 15%;
}

div > h4 > small, .input-icon {
    cursor: pointer;
}
