@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }

.wrapper{
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.main-panel{
    height: calc(100vh - 60px) !important;
    overflow: auto;
}

.content{
    margin: 0;
    padding: 4px 3px;
}

#root{
    height: 100vh;
    overflow: hidden;
}

.btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-notification-icon {
    min-width: 100% !important;
}

.btn-danger {
    min-width: 100% !important;
    background: #8461C9 !important;
}

.btn-simple,
.btn-secondary{
    min-width: 150px;
}

.sidebar{
    @include scrollbars(5px, grey, linear-gradient(0deg, #131042 0%, #131042 100%));
    overflow-x: hidden;
    height: calc(100vh - 60px) !important;  
    z-index: 1;
}

.white-content .table > tbody > tr > td{
  color: #6236FF !important;
  font-size: 14px;
}

#transgression, #transgressionCategory{
  color: #222a42 !important;
}

.view-document-button{
  width: 40px !important;
}

.edit-document-button{
  width: 40px !important;
}

.nav-pills.nav-pills-modal .nav-item .nav-link.active, .nav-pills.nav-pills-modal .nav-item .nav-link.active:focus, .nav-pills.nav-pills-modal .nav-item .nav-link.active:hover {
  background: #BD7AE3;
  background: -webkit-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: -o-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: -moz-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%); }

.nav-pills.nav-pills-info .nav-item .nav-link.active, .nav-pills.nav-pills-info .nav-item .nav-link.active:focus, .nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background: #BD7AE3;
  background: -webkit-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: -o-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: -moz-linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%);
  background: linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%); }

.prepare-letter-button{
  width: auto !important;
  min-width: 379.1px;
  border-radius: 2px !important;
  offset: 0.15px;
  font-size: 13px;
  letter-spacing: 1px;
}

.guidance-modal-button{
  min-width: 379.1px !important;
  border-radius: 2px !important;
  offset: 0.15px;
  font-size: 13px;
  letter-spacing: 1px;
  background: linear-gradient(225deg,  #6236FF 0%, #B620E0 100%);
}

.disabled{
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}
