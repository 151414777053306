.allegations-summary {
  max-width: 500px;
}

.guidance-modal-button {
  background: linear-gradient(186.86deg, #6236FF 0%, #B620E0 100%);
  box-shadow: 0px 15px 20px rgba(175, 175, 175, 0.223987);
  border-radius: 2px;
  min-width: 343px;
}

.guidance-modal-header {
  background: linear-gradient(175.96deg, #3023AE 0%, #C86DD7 101.5%);
  border-radius: 2px 2px 0px 0px;
}
