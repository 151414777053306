.no-margin {
    margin-bottom: 0px !important;
}
.square-radius {
    border-radius: 0 !important;
}

.control {
    cursor: pointer;
}

.datetime-icon {
    color: #6D7278;
    /*font-size: 1.1em;*/
    position: absolute;
    right: 0.6em;
    top: 0.8em;
}

.tableData {
    padding: 0 !important;
    margin: 0 !important;
}

.add-remove-icon {
    border: 1px solid white !important;
    background: white !important;
}