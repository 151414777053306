.dropdown-navbar{

  li {

  }

}

.logo{
  height: 40px;
}