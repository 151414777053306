.view-case-button {
    height: 30px;
    width: 30px;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    color: white;
    font-size: 13px;
    background: #50E3C2;
    margin-right: 10px;
    padding: 0;
}

.case-upload-button {
    height: 40px;
    width: 40px;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    color: white;
    font-size: 13px;
    background: #8461C9;
    margin-right: 10px;
    padding: 0;
}

.pagination-item{
    color: grey !important
}

tr:nth-child(even) {
    // Note: Removing styling from background row
    // background-color: #f2f2f2;
  }
  